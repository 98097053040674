<template>
    <div>

    </div>
</template>

<script>

import io from 'socket.io-client'
import { EventBus } from '@/main.js'

export default {
    name: 'socketio-client-connect',

    data: () => ({
        socket: null,
        socket_address: null,
        socket_connection: null,
        socket_token: null,
        interval1: null
    }),

    methods: {
        rpiData() {
            this.interval1 = setInterval(() => {
                this.$store.dispatch('SETUP_RPI_DATA')
            }, 1000);
        }
    },

    created() {
        this.socket_address = process.env.VUE_APP_DATA_SOCKETIO
        this.socket_connection = process.env.VUE_APP_DATA_SOCKETIO_CONNECTION
        this.socket_token = process.env.VUE_APP_DATA_SOCKETIO_TOKEN
    },

    mounted() {
        //window.console.log("SocketIO client mounted")
        //window.console.log("SOCKET ADDRESS: ", this.socket_address)
        //window.console.log("SOCKET CONNECTION: ", this.socket_connection)
        //window.console.log("SOCKET TOKEN: ", this.socket_token)
        
        if(this.socket_connection == 1 || this.socket_connection == true) {
            if(this.socket_address !== "" && this.socket_address !== null) {
                this.socket = io(this.socket_address, {
                    //this.socket = io("http://socket.conforma.si:3000", {
                    secure: true,
                    rejectUnauthorized: false,
                    auth: {
                        token: this.socket_token
                    }
                })

                this.socket.on("connect", () => {
                    window.console.log("Connected (" + this.socket.id + ")"); // x8WIv7-mJelg7on_ALbx

                    this.rpiData();

                });
                
                this.socket.on("disconnect", () => {
                    window.console.log("Disconnected..."); // undefined
                    clearInterval(this.interval1)
                });

                // client-side
                this.socket.on("connect_error", (err) => {
                    window.console.log(err)
                    window.console.log(err.message); // prints the message associated with the error
                    //alert(err.message)
                    //alert(err)
                });

                this.socket.on('fooBar', (data) => {
                    window.console.log("SOCKETIO [fooBar]")
                    window.console.log(data)
                })

                this.socket.on('tolminska-korita/identification', (data) => {
                    window.console.log("SOCKETIO [identification]")
                    window.console.log(data)

                    //EventBus.$emit('identification', data);
                    let payload = data
                    data.timestamp = Date.now()

                    window.console.log(payload)

                    this.$store.dispatch('DEVICE_IDENTIFICATION', payload)
                })
            }
        }
    },

    beforeDestroy() {
        if(this.socket !== null) {
            this.socket.disconnect();
            window.console.log("Socket disconnect")
        }

        clearInterval(this.interval1)
    }

    
}

</script>